
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

@import 'swiper/scss';
@import 'swiper/scss/navigation';
@import 'swiper/scss/pagination';
@import 'swiper/scss/lazy';
@import 'swiper/css/free-mode';
@import 'swiper/css/mousewheel';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$custom-primary: (
    50 : #f3e4e0,
    100 : #e1bbb3,
    200 : #cd8e80,
    300 : #b9604d,
    400 : #aa3e26,
    500 : #9b1c00,
    600 : #931900,
    700 : #891400,
    800 : #7f1100,
    900 : #6d0900,
    A100 : #ffa09c,
    A200 : #ff6e69,
    A400 : #ff3d36,
    A700 : #ff241d,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$custom-accent: (
    50 : #ececec,
    100 : #d0d0d0,
    200 : #b1b1b1,
    300 : #929292,
    400 : #7a7a7a,
    500 : #636363,
    600 : #5b5b5b,
    700 : #515151,
    800 : #474747,
    900 : #353535,
    A100 : #f49898,
    A200 : #ef6b6b,
    A400 : #ff2727,
    A700 : #ff0e0e,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$custom-tertiary: (
    50 : #e2f0ff,
    100 : #b6daff,
    200 : #85c2ff,
    300 : #54a9ff,
    400 : #2f96ff,
    500 : #0a84ff,
    600 : #097cff,
    700 : #0771ff,
    800 : #0567ff,
    900 : #0354ff,
    A100 : #ffffff,
    A200 : #f2f6ff,
    A400 : #bfd0ff,
    A700 : #a6beff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$primary: #9b1c00;
$accent: #636363;
$tertiary: #0a84ff;

$spiritsrim-primary: mat.define-palette($custom-primary);
$spiritsrim-accent: mat.define-palette($custom-accent);
$spiritsrim-tertiary: mat.define-palette($custom-tertiary);

// The warn palette is optional (defaults to red).
$spiritsrim-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$spiritsrim-theme: mat.define-light-theme((
  color: (
    primary: $spiritsrim-primary,
    accent: $spiritsrim-accent,
    tertiary: $spiritsrim-tertiary,
    warn: $spiritsrim-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($spiritsrim-theme);

/* You can add global styles to this file, and also import other style files */

$lightText: #F8F9FA;

html, body { height: 100%; }
body {
  margin: 0;
  font-family: "Helvetica Neue", sans-serif; 
  background-color: black;
  color: white;
}

h1 {
  font-size: 41px;
  line-height: 61.5px;
  font-weight: 700;
  margin: 0;
}

h2 {
  font-size: 32px;
  font-weight: 500;
  line-height: 38.4px;
  margin: 0;
}

h4 {
  font-size: 24px;
  font-weight: 500;
  line-height: 28.8px;
  margin: 0;
}

h5 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  margin: 0;
}

h6 {
  font-size: 16px;
  line-height: 19.2px;
  margin: 0;
}

small {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
}

.mat-mdc-dialog-container .mat-mdc-dialog-surface {
  background-color: rgba(28, 28, 30, 1) !important;
}

.mat-bottom-sheet-container {
  background: rgba(28, 28, 30, 1) !important;
  max-width: 375px !important;
  width: 100%;
  overflow: hidden !important;
}

.text-primary {
  color: $primary;
}

.border-primary {
  border-color: $primary;
}

.background-primary {
  background-color: $primary !important;
}

.text-white {
  color: white !important;
}

.border-white {
  border-color: white !important;
}

.text-light {
  color: $lightText !important;
  opacity: .8;
}

.shadow-primary {
  box-shadow: 0px 0px 4px 0px $primary !important;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
}


/* CREAR ARCHIVO CUSTOM STYLES */
.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  -webkit-mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  -webkit-mask-size: 200% 100%;
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0%;
    mask-position: -200% 0%;
  }
}